import { useRef, useLayoutEffect } from 'react';

// support server-side rendering (SSR);
// check if the DOM is ready and the window context exists
const isBrowser = typeof window !== 'undefined';

/**
 * get the current scroll position
 * @returns {{x: number, y: number}}
 */
const getScrollPosition = () => {
  if (!isBrowser) {
    return { x: 0, y: 0 };
  }

  const target = document.body;
  const position = target.getBoundingClientRect();

  return { x: position.left, y: position.top };
};

/**
 * useScrollPosition Hook
 *
 * inspired/borrowed from https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
 *
 * @returns {{x: number, y: number}}
 */

type ScrollPosType = { x: number; y: number };
export type ScrollPosHistoryType = { prevPos: ScrollPosType; curPos: ScrollPosType };
type ScrollPosCallbackType = ({ prevPos, curPos }: ScrollPosHistoryType) => void;

function useScrollPosition(onScroll: ScrollPosCallbackType) {
  const position = useRef(getScrollPosition()); // store the element we're checking in a ref
  const throttleTimeout = useRef(0); // track throttling
  const wait = 200; // throttle the scroll tracking for performance

  /**
   * Hook is tightly bound to DOM so implement it inside an Effect hook
   *    so we can control which renders we want to fire it on when values change.
   *
   * useLayoutEffect vs useEffect:
   *     runs synchronously immediately after React has performed all DOM mutations
   *    (works the same way as componentDidMount and componentDidUpdate:
   *      runs immediately after DOM has been updated, but before the browser has re-painted)
   */
  useLayoutEffect(() => {
    const handleEffect = () => {
      const curPos = getScrollPosition();

      onScroll({ prevPos: position.current, curPos });
      position.current = curPos;
      throttleTimeout.current = 0;
    };

    const handleScroll = () => {
      if (!throttleTimeout.current) {
        throttleTimeout.current = window.setTimeout(handleEffect, wait);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [onScroll]);
}

export default useScrollPosition;
