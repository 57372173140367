export const COLORS = {
  // DEFAULT: '', //transparent
  // PRIMARY: 'primary', // blue
  // ERROR: 'error', // red
  // SUCCESS: 'success', // green
  DARK: 'dark',
  LIGHT: 'light',
};

export const SIZES = {
  DEFAULT: '',
  LARGE: 'large',
  SMALL: 'small',
};
