import styles from './Button.module.scss';

// todo - TypeDoc unable to resolve type definitions when dereferencing Props
//  - should I move type definition into the function definition at 27?
type Props = {
  text: string;
  label: string;
  value?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & typeof defaultProps;

const defaultProps = {
  value: '',
};

/**
 * ## Button
 * @group  Atom
 *
 * @param {string} text the button text
 * @param {string} label the aria/title text
 * @param {string} value value on click
 * @param {Function} handleClick onclick callback handler
 *
 * @constructor
 */
function Button({ text, label, value, handleClick }: Props) {
  return (
    <button
      type='button'
      className={styles.button}
      title={label}
      aria-label={label}
      value={value}
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

Button.defaultProps = defaultProps;
export default Button;
