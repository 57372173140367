import SEO from 'constants/seo';
import { SeoPage, SeoPageTypeProp } from 'types/seoTypes';
import { Helmet } from 'react-helmet-async';

function PageHelmet({ pageType }: SeoPageTypeProp) {
  const { siteDesc, siteName } = SEO;
  const { imgUrl, pageTitle, pageDesc } = SEO[pageType] as SeoPage;

  return (
    <Helmet prioritizeSeoTags>
      <meta property='og:title' content={pageTitle || siteName} />
      <meta property='og:description' content={pageDesc || siteDesc} />
      <meta property='og:image' content={imgUrl} />
      <meta property='og:image:secure_url' content={imgUrl} />
      <meta property='twitter:image' content={imgUrl} />
      <title>{pageTitle || siteName}</title>
    </Helmet>
  );
}

export default PageHelmet;
